import React from 'react';
import './Products.css';
import alohaLogo from '../../assets/aloha.png';
import jobShinobiLogo from '../../assets/jobshinobi-logo.png';

const Products = () => {
    const productList = [
        { 
            name: 'JobShinobi (Coming Soon)', 
            link: 'https://example.com/product1', 
            description: 'JobShinobi is a product designed to help you manage your job applications and interviews. It provides a simple and intuitive interface to keep track of your progress and tasks.',
            image: jobShinobiLogo
        },
        { 
            name: 'AlohaAI', 
            link: 'https://apps.apple.com/us/app/alohaai-chat-bot-assistant/id6450401286', 
            description: 'AlohaAI is an AI-powered chatbot, powered by GPT-4o, and Gemini.',
            image: alohaLogo
        },
    ];

    return (
        <div className="productsContainer">
            <div className="products">
                <h1 className="productsHeader">Our Products</h1>
                <ul className="productList">
                    {productList.map((product, index) => (
                        <li key={index} className="productItem">
                            <a href={product.link} target="_blank" rel="noopener noreferrer" className="productLink">
                                <img src={product.image} alt={product.name} className="productImage" />
                                <div className="productContent">
                                    <h2 className="productName">{product.name}</h2>
                                    <p className="productDescription">{product.description}</p>
                                </div>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Products;
