import React from "react";
import "./Privacy.css"; // New CSS file for Privacy component

const Privacy = () => {
    return (
        <div className="privacyContainer">
            <div className="privacy">
                <h1 className="privacyHeader">Privacy Policy</h1>
                <p className="privacyParagraph">
                    Your privacy is important to us. This privacy policy explains how we collect, use, and protect your information.
                </p>
                <div className="privacySection">
                    <h3 className="privacyTitle">Information We Collect</h3>
                    <p className="privacyDescription">
                        We collect information you provide directly to us, such as when you create an account, use our app, or contact us for support. This information may include your name, email address, and any other details you choose to provide.
                    </p>
                </div>
                <div className="privacySection">
                    <h3 className="privacyTitle">How We Use Information</h3>
                    <p className="privacyDescription">
                        We use the information we collect to operate, maintain, and improve our app, to communicate with you, and to provide customer support. We may also use the information to personalize your experience and to understand how users interact with our app.
                    </p>
                </div>
                <div className="privacySection">
                    <h3 className="privacyTitle">Sharing Information</h3>
                    <p className="privacyDescription">
                        We do not share your personal information with third parties except as necessary to operate our app, comply with the law, or protect our rights. We may share non-personal information, such as aggregate usage data, with third parties.
                    </p>
                </div>
                <div className="privacySection">
                    <h3 className="privacyTitle">Security</h3>
                    <p className="privacyDescription">
                        We take reasonable measures to protect your information from unauthorized access, loss, misuse, or alteration. However, no method of transmission over the Internet or electronic storage is completely secure.
                    </p>
                </div>
                <div className="privacySection">
                    <h3 className="privacyTitle">Changes to This Policy</h3>
                    <p className="privacyDescription">
                        We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on our app. You are advised to review this policy periodically for any changes.
                    </p>
                </div>
                <div className="privacySection">
                    <h3 className="privacyTitle">Contact Us</h3>
                    <p className="privacyDescription">
                        If you have any questions about this privacy policy, please contact us through the contact us page.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Privacy;
