import React, { useState } from 'react';
import './Nav.css';
import { NavLink } from 'react-router-dom';

const Nav = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <div className="nav">
            <div className="nav-logo">🚀</div>
            <div className="hamburger" onClick={toggleNav}>
                ☰
            </div>
            <ul className={`nav-links ${isNavOpen ? 'active' : ''}`}>
                <li><NavLink to="/" onClick={toggleNav}>Home</NavLink></li>
                <li><NavLink to="/products" onClick={toggleNav}>Products</NavLink></li>
                <li><NavLink to="/about" onClick={toggleNav}>About</NavLink></li>
                <li><NavLink to="/contact" onClick={toggleNav}>Contact</NavLink></li>
                <li><NavLink to="/privacy" onClick={toggleNav}>Privacy</NavLink></li>
            </ul>
        </div>
    );
}

export default Nav;
