import React from 'react';
import './Home.css';

const Home = () => {
    return (
        <div className="homeContainer">
            <div className="home">
                <h1 className="homeHeader"> ArkTech </h1>
                <p className="homeParagraph">
                    We aim to build products that delight our customers and provide value to the world.
                </p>
                <div className="stars">
                    <span className="star">⭐</span>
                </div>
            </div>
        </div>
    );
}

export default Home;
