import "./About.css";

const About = () => {
    return (
        <div className="aboutContainer">
            <div className="about">
                <h1 className="aboutHeader"> About Us </h1>
                <p className="aboutParagraph">
                    ArkTech was founded in 2021 with the goal of creating innovative software solutions. Our team is passionate about technology and is dedicated to building products that make a difference.
                </p>
            </div>
        </div>
    );
}

export default About;
