import { useState } from 'react';
import './Contact.css'; // New CSS file for Contact component

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        // Validation
        if (!formData.name.trim()) {
            alert('Name is required!');
            return;
        }

        if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
            alert('Enter a valid email!');
            return;
        }

        if (!formData.subject.trim()) {
            alert('Subject is required!');
            return;
        }

        if (!formData.message.trim()) {
            alert('Message is required!');
            return;
        }

        try {
            const response = await fetch('https://pgarkdevv2.azurewebsites.net/api/send_email?code=HYpGTPWEQKk12VRjBFfBvHx39r1ZDZ8UaSetQk9hpbfVAzFueu192g%3D%3D', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                alert('Email sent successfully!');
            } else {
                const errorData = await response.json();
                alert(`Failed to send email: ${errorData.message}`);
            }
        } catch (error) {
            alert('An error occurred. Please try again later.');
            console.error(error);
        }
    };

    return (
        <div className="contactContainer">
            <div className="contact">
                <h1 className="contactHeader"> Contact Us </h1>
                <p className="contactParagraph">
                    Please contact us if you have any questions or concerns. We will get back to you as soon as possible.
                </p>
                <form className="contactForm" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        value={formData.subject}
                        onChange={handleChange}
                    />
                    <textarea
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                    ></textarea>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default Contact;
